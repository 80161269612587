<template>
  <v-container>
    <v-card class="mx-auto cardstyle" max-width="800" raised>
    <v-img
      height="250"
      src="/static/header.jpg"
    ></v-img>      

      <v-card-title>Inscripción GIRA Mujeres</v-card-title>
      <v-card-text>
        <ValidationObserver ref="observer" v-slot="{ invalid, passes }">
          <form @submit.prevent="passes(submit)">
            <template v-if="$store.state.perfil === 'quiero-emprender'">
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-select
                      v-model="$store.state.registryField_28521"
                      label="¿Has emprendido con anterioridad?  "
                      required
                      :items="[
                        { text: 'Nunca he emprendido', value: 1 },
                        { text: 'He emprendido ya anteriomente', value: 2 },
                      ]"
                      filled
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      ¿Qué te motiva a participar en GIRA V en la categoría
                      “Quiero Emprender”?
                    </p>
                    <v-textarea
                      v-model="$store.state.registryField_28522"
                      required
                      filled
                      auto-grow
                      counter="1000"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-select
                      v-model="$store.state.registryField_28523"
                      label="¿Cuentas con una idea de emprendimiento?"
                      required
                      :items="[
                        { text: 'Si', value: 'Si' },
                        { text: 'No', value: 'No' },
                      ]"
                      filled
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      En caso que cuentes con una idea, ¿nos podrías resumir
                      brevemente de qué se trata? Para ello te animamos a que
                      plantees tu respuesta siguiendo este orden: Mi idea se
                      llama... Y tiene por objetivo... Está dirigida a… Va a
                      tener su ámbito de impacto geográficamente en…. Y es
                      novedosa o única porque… (Si aún no tienes una idea marca
                      esta casilla de respuesta con una X)
                    </p>
                    <v-textarea
                      v-model="$store.state.registryField_28524"
                      required
                      filled
                      auto-grow
                      counter="1000"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      En caso de que cuentes con una idea, ¿cómo surge tu idea
                      de emprendimiento? (Si aún no tienes una idea marca esta
                      casilla de respuesta con una X)
                    </p>
                    <v-textarea
                      v-model="$store.state.registryField_28525"
                      required
                      filled
                      auto-grow
                      counter="1000"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      Cuentes ya o no con una idea, ¿qué tipo de necesidades (en tu barrio, en tu pueblo o ciudad, en tu comunidad, etc) o problemáticas te preocupan?
                    </p>
                    <v-textarea
                      v-model="$store.state.registryField_28526"
                      required
                      filled
                      auto-grow
                      counter="1000"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>              
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      En el caso de que no cuentes con una idea ¿Qué tipo de
                      emprendimiento te gustaría montar? (Si ya tienes una idea
                      marca esta casilla de respuesta con una X)
                    </p>
                    <v-textarea
                      v-model="$store.state.registryField_28527"
                      required
                      filled
                      auto-grow
                      counter="1000"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <p class="body-2">
                    Para poder emprender, ¿en qué aspectos consideras que
                    necesitas un mayor apoyo?
                  </p>
                  <v-checkbox
                    dense
                    v-model="$store.state.registryField_28528"
                    label="Presentar mi idea de forma atractiva"
                    value="Presentar mi idea de forma atractiva"
                  ></v-checkbox>
                  <v-checkbox
                    dense
                    v-model="$store.state.registryField_28528"
                    label="Conocer si es una idea viable a nivel económico"
                    value="Conocer si es una idea viable a nivel económico"
                  ></v-checkbox>
                  <v-checkbox
                    dense
                    v-model="$store.state.registryField_28528"
                    label="Crear mi marca"
                    value="Crear mi marca"
                  ></v-checkbox>
                  <v-checkbox
                    dense
                    v-model="$store.state.registryField_28528"
                    label="Darle forma a la idea: pensar un plan de negocio"
                    value="Darle forma a la idea: pensar un plan de negocio"
                  ></v-checkbox>
                  <v-checkbox
                    dense
                    v-model="$store.state.registryField_28528"
                    label="Conocer qué opinan mis potenciales clientes y cómo atraerlos"
                    value="Conocer qué opinan mis potenciales clientes y cómo atraerlos"
                  ></v-checkbox>
                  <v-checkbox
                    dense
                    v-model="$store.state.registryField_28528"
                    label="Motivación personal para llevar a cabo la idea"
                    value="Motivación personal para llevar a cabo la idea"
                  ></v-checkbox>
                  <v-checkbox
                    dense
                    v-model="$store.state.registryField_28528"
                    label="Identificar mi público objetivo"
                    value="Identificar mi público objetivo"
                  ></v-checkbox>
                  <v-checkbox
                    dense
                    v-model="$store.state.registryField_28528"
                    label="Aprender aspectos básicos de emprendimiento"
                    value="Aprender aspectos básicos de emprendimiento"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      Para finalizar cuéntanos un poco sobre ti. El
                      emprendimiento es una pasión que nos mueve por dentro y
                      que con tiempo y dedicación se desarrolla, crece y
                      evoluciona. ¿Qué factores te motivan a emprender?
                    </p>
                    <v-textarea
                      v-model="$store.state.registryField_28529"
                      required
                      filled
                      auto-grow
                      counter="1000"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      ¿Qué te motiva a participar de GIRA V en la categoría
                      “Tengo un negocio”?
                    </p>
                    <v-textarea
                      v-model="$store.state.registryField_28530"
                      required
                      filled
                      auto-grow
                      counter="1000"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      Si estás interesada en ser parte de esta categoría es
                      porque ya cuentas con un proyecto de emprendimiento o
                      negocio en marcha, ¿nos podrías contar en qué momento te
                      encuentras?
                    </p>
                    <v-select
                      v-model="$store.state.registryField_28531"
                      required
                      :items="[
                        {
                          text:
                            'Tengo una idea y ya he hecho un plan de negocio',
                          value: 1,
                        },
                        {
                          text:
                            'He puesto en marcha mi proyecto y ya estoy teniendo mis primeras ventas',
                          value: 2,
                        },
                        {
                          text:
                            'He consolidado mi emprendimiento, estoy vendiendo y estoy pensando en nuevos productos/servicios',
                          value: 3,
                        },
                        {
                          text:
                            'Estoy vendiendo varios productos/servicios y estoy pensando en expandirme fuera de mi pueblo/ciudad',
                          value: 4,
                        },
                      ]"
                      filled
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      ¿Está tu organización constituida jurídicamente?
                    </p>
                    <v-select
                      v-model="$store.state.registryField_28532"
                      required
                      :items="[
                        { text: 'Si', value: 'Si' },
                        { text: 'No', value: 'No' },
                      ]"
                      filled
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      ¿A cuál de los siguientes sectores pertenece tu idea o
                      proyecto de emprendimiento?
                    </p>
                    <v-select
                      v-model="$store.state.registryField_28533"
                      required
                      :items="[
                        { text: 'Agrícola (producción y cultivos)', value: 1 },
                        { text: 'Alimentación', value: 2 },
                        { text: 'Industrial', value: 3 },
                        { text: 'Energético', value: 4 },
                        {
                          text: 'Servicios (mantenimiento, limpieza, cuidados)',
                          value: 5,
                        },
                        { text: 'Marketing y comunicación', value: 6 },
                        { text: 'Textil (producción y diseño)', value: 7 },
                        { text: 'Consultoría', value: 8 },
                        { text: 'Hostelería', value: 9 },
                        { text: 'Comercio minorista', value: 10 },
                        { text: 'Artesanía / producción artesanal', value: 11 },
                        {
                          text: 'Economía circular, reciclaje y reutilización',
                          value: 12,
                        },
                        { text: 'Ocio y tiempo libre', value: 13 },
                        { text: 'Otro', value: 14 },
                      ]"
                      filled
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row v-if="parseInt($store.state.registryField_28533) === 14">
                <v-col cols="12">
                  <v-text-field
                    v-model="$store.state.registryField_28548"
                    label="Indícanos tu sector"
                    filled
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      Vamos a centrarnos ahora en tu emprendimiento, ¿nos
                      podrías resumir brevemente de qué se trata? Para ello te
                      animamos a que plantees tu respuesta siguiendo este orden:
                      Mi idea se llama... Y tiene por objetivo... Está dirigida
                      a… tiene su ámbito de impacto geográficamente en…. Y es
                      novedosa o única porque…
                    </p>
                    <v-textarea
                      v-model="$store.state.registryField_28534"
                      required
                      filled
                      auto-grow
                      counter="1000"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      Para conocer un poco más en detalle sobre tu
                      emprendimiento, ¿has validado su viabilidad económica?
                      ¿Has validado tu idea con clientes actuales?
                    </p>
                    <v-textarea
                      v-model="$store.state.registryField_28535"
                      required
                      filled
                      auto-grow
                      counter="1000"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      ¿Ha afectado el COVID-19 a tu situación? ¿Has tenido que
                      hacer alguna adaptación por el contexto COVID-19?
                      Cuéntanos brevemente cómo lo has hecho
                    </p>
                    <v-textarea
                      v-model="$store.state.registryField_28536"
                      required
                      filled
                      auto-grow
                      counter="1000"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <p class="body-2">
                    Para desarrollar tu negocio, ¿En qué aspectos consideras que
                    necesitas un mayor apoyo? ELIGE MÁXIMO 3
                  </p>
                  <v-checkbox
                    dense
                    v-model="$store.state.registryField_28537"
                    label="Marketing Digital - redes social y página web"
                    value="Marketing Digital - redes social y página web"
                  ></v-checkbox>
                  <v-checkbox
                    dense
                    v-model="$store.state.registryField_28537"
                    label="Branding"
                    value="Branding"
                  ></v-checkbox>
                  <v-checkbox
                    dense
                    v-model="$store.state.registryField_28537"
                    label="Aspectos jurídicos y fiscales"
                    value="Aspectos jurídicos y fiscales"
                  ></v-checkbox>
                  <v-checkbox
                    dense
                    v-model="$store.state.registryField_28537"
                    label="Modelo de crecimiento"
                    value="Modelo de crecimiento"
                  ></v-checkbox>
                  <v-checkbox
                    dense
                    v-model="$store.state.registryField_28537"
                    label="Finanzas y financiación"
                    value="Finanzas y financiación"
                  ></v-checkbox>
                  <v-checkbox
                    dense
                    v-model="$store.state.registryField_28537"
                    label="Estrategia de impacto social y medioambiental"
                    value="Estrategia de impacto social y medioambiental"
                  ></v-checkbox>
                  <v-checkbox
                    dense
                    v-model="$store.state.registryField_28537"
                    label="Equipos y colaboradores"
                    value="Equipos y colaboradores"
                  ></v-checkbox>
                  <v-container>
                    <v-row no-gutters>
                      <v-col cols="auto">
                        <v-checkbox
                          dense
                          v-model="$store.state.registryField_28537"
                          label="Otros"
                          value="Otros"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="$store.state.registryField_28549"
                          label="Indícanos cual"
                          filled
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      Sobre el futuro de tu proyecto, ¿cómo visualizas tu negocio en el próximo año?
                    </p>
                    <v-textarea
                      v-model="$store.state.registryField_28538"
                      required
                      filled
                      auto-grow
                      counter="1000"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      Tus canales de comunicación y marketing, ¿están en redes sociales y/o página web? Compartenos por aquí esa información:
                    </p>
                    <v-textarea
                      v-model="$store.state.registryField_28539"
                      required
                      filled
                      auto-grow
                      counter="1000"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      Cuéntanos sobre ti. El emprendimiento es una pasión que nos mueve por dentro y que con tiempo y dedicación se desarrolla, crece y evoluciona. ¿Qué factores te motivan a emprender?
                    </p>
                    <v-textarea
                      v-model="$store.state.registryField_28540"
                      required
                      filled
                      auto-grow
                      counter="1000"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>

            </template>
            <v-btn
              class="mr-4"
              type="submit"
              :disabled="invalid"
              :to="{ name: 'Gracias' }"
              color="error"
              large
            >
              Siguiente
            </v-btn>
          </form>
        </ValidationObserver>
      </v-card-text>
    </v-card>
    <v-container>
      <v-row>
        <v-col class="text-center"><a href="/static/bases-v-edicion-giramujeres.pdf" target="_blank">BASES DE PARTICIPACIÓN V EDICIÓN GIRA MUJERES DE COCA-COLA</a></v-col>
      </v-row>
      <v-row>
        <v-col class="text-center"><a href="/static/folleto-cadena-valor.pdf" target="_blank">FOLLETO CADENA DE VALOR</a></v-col>
      </v-row>
    </v-container>

  </v-container>  
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";

extend("required", { ...required, message: "Este campo es obligatorio." });

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    submit() {
      this.$refs.observer.validate();
    },
  },

  data: function () {
    return {};
  },
};
</script>